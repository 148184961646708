@use "../../converSight/variables" as *;
@use "../../converSight/mixins" as *;

.conversight.conversight-flex {
  display: flex;
  text-align: -webkit-auto;

  &-inline {
    display: inline-flex;
  }

  &-row {
    flex-direction: row;
  }

  &-column {
    flex-direction: column;
  }

  &-fill-children > * {
    flex: 1 1;
    flex-basis: 400px;
  }

  &-basis {
    &-content {
      > * {
        flex-basis: content;
      }
    }
  }

  &-align-items {
    &-flex-start {
      align-items: flex-start;
    }
    &-flex-end {
      align-items: flex-end;
    }
    &-center {
      align-items: center;
    }
    &-stretch {
      align-items: stretch;
    }
    &-baseline {
      align-items: baseline;
    }
  }

  &-align {
    &-center {
      align-items: center;
    }

    &-start {
      align-items: flex-start;
    }

    &-end {
      align-items: flex-end;
    }
  }

  &-align-self {
    &-center {
      align-self: center;
    }

    &-start {
      align-self: flex-start;
    }

    &-end {
      align-self: flex-end;
    }
  }

  &-justify {
    &-center {
      justify-content: center;
    }

    &-start {
      justify-content: flex-start;
    }

    &-end {
      justify-content: flex-end;
    }

    &-space-between {
      justify-content: space-between;
    }
  }

  &-wrap {
    flex-wrap: wrap;
  }

  &-text-align {
    &-left {
      text-align: left;
    }

    &-right {
      text-align: right;
    }

    &-center {
      text-align: center;
    }
  }

  // @include devices(xsmall) {
  //   flex-direction: column;
  // }

  @include gaps();
  @include grow();
  @include shrink();
  @include margins();
  @include padding();
}
