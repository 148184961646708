@use "../../converSight/variables" as *;

.add-new-location-summary {
  .header {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .sub-header {
    text-align: center;
  }
  .img-loader,
  .lottie-gif {
    width: 250px;
  }

  button {
    width: 100%;
  }
}
