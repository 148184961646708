@use "../../converSight/variables" as *;

.agent-hint-tooltip {
  display: block !important;
  margin-top: 8px;
  .tooltip-inner {
    background: $base-blue;
    color: $white;
    border-radius: $radius-small;
    padding: 18px 16px;
    text-align: left;
    min-width: 250px;
    max-width: 250px;
    button {
      margin-top: 10px;
      width: 100%;
    }
  }
}

.tooltip .arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #3d52f6 !important;
}
