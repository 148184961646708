@use "../../converSight/variables" as *;

.conversight.line-item {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid $divider;
  margin-bottom: 12px;
  align-items: center;
  .item,
  .amount {
    margin: 8px 0;
  }

  .description {
    white-space: pre-line;
  }
  &.total {
    font-weight: $font-weight-medium;
  }
}
