// Spacing details.
// Based on a 4px / 0.25rem grid, each spacer is multplication of 4px / 0.25rem.
// 16 px = 1 rem

// 4px
$xsmall: 0.25rem;

// 8px
$small: 0.5rem;

// 12px
$small-medium: 0.75rem;

// 16px
$medium: 1rem;

// 24px
$medium-large: 1.5rem;

// 32px
$large: 2rem;

// 36px
$xmlarge: 2.25rem;

// 40px
$xlarge: 2.5rem;

// 64px
$xxlarge: 4rem;
