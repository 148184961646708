@use "../../converSight/variables" as *;

div.conversight.agent-guide {
  display: contents;

  background-clip: padding-box;
  background-color: #fff;
  border-radius: $radius-large;
  overflow: hidden;

  @include devices(medium) {
    flex-wrap: nowrap;
    overflow: auto;
  }

  &.medium {
    display: flex;
    justify-content: space-between;
    width: 100%;
    .agent-guide-container {
      @include devices(desktop) {
        height: 750px;
      }
    }
  }

  .agent-guide-container {
    position: relative;
    overflow: hidden;
    display: flex;
    flex-grow: 1;

    @include devices(medium) {
      display: flex;
      overflow: unset;
      flex-direction: column;
      padding-left: $medium;
      padding-right: $medium;
    }

    .agent-guide-meta {
      margin: $large $medium-large 0 $medium-large;
      display: flex;

      @include devices(desktop) {
        width: 50%;
      }
      @include devices(medium) {
        flex-direction: row-reverse;
        gap: $medium;
        align-items: center;
        justify-content: flex-end;
        margin: $large $medium 0 0;
      }

      .agent-guide-heading {
        display: grid;
        grid-template-areas:
          "back title"
          ". flourish"
          " . points";
        grid-template-columns: max-content 2fr;
        grid-template-rows: auto auto 1fr;

        @include devices(medium) {
          display: flex;
          flex-direction: column;
          gap: $small;
          grid-template-areas: unset;
          grid-template-columns: unset;
          grid-template-rows: unset;
        }

        .modal-back-button {
          grid-area: back;
          margin-right: $small;

          @include devices(medium) {
            position: absolute;
            grid-area: unset;
            left: 0;
            top: 0;
          }
        }

        .modal-title {
          grid-area: title;
          margin-bottom: $medium-large;

          @include devices(medium) {
            margin-bottom: 0;
          }
        }

        .flourish {
          grid-area: flourish;
          margin-bottom: $small;
        }

        .selling-points {
          grid-area: points;

          @include devices(medium) {
            display: none;
          }
        }
      }

      .agent-with-hint-container {
        position: absolute;
        bottom: 0px;
        left: -77px;
        @include devices(medium) {
          display: flex;
          position: relative;
          bottom: unset;
          left: unset;
        }
      }
    }

    .agent-guide-content {
      margin: $medium-large;
      align-self: center;

      width: 100%;
      max-width: 500px;

      &.custom-action {
        margin-top: 0;
        @include devices(xMobile) {
          margin-left: 0;
        }
      }

      // TODO: This is a hack fix for the Payment Method page in subscription flow. Let's try to remove it.
      form {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
      }
    }
  }
}
