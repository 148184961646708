@use "../../converSight/variables" as *;

.conversight.bullet-item {
  display: flex;
  align-items: center;

  .bullet-text-container {
    h3,
    p {
      display: inline;
    }
  }
  svg {
    color: $base-dark-blue;

    &.base-cyan {
      color: $base-cyan;
    }
    &.label-color {
      color: $label-color;
    }
    &.base-red {
      color: $base-red;
    }
    &.base-blue {
      color: $base-blue;
    }
    &.base-yellow {
      color: $base-yellow;
    }
    &.default-color {
      color: $border;
    }
  }
}
.label-margin {
  margin-right: 15px;
}
