@use "../../converSight/variables" as *;

.conversight.no-results-img {
  padding-top: 4px;
  width: 46.54px;
  height: 48px;
}

.conversight.agent-dropdown-search {
  margin-bottom: 8px;
  margin-left: 2px;
  height: 32px;

  input {
    &::placeholder {
      font-style: normal;
    }
  }
  .cancel-icon {
    width: 16px;
    height: 16px;
  }
}
