@use "../../converSight/variables" as *;

div.conversight.cs-nav-tabs {
  display: flex;

  &.no-gap {
    gap: none;
  }

  &.small-gap {
    gap: $xsmall;
  }

  > div.conversight.cs-nav-tab,
  > div.conversight.cs-nav-item {
    display: inline-flex;
    align-items: center;
    gap: $small;

    /* If the tab has an active child, set the background color. */
    &:has(> .active) {
      border-bottom: 2px solid $base-blue;
    }

    /* Adjust the anchor styling. */
    > a {
      display: inline-block;
      padding: $small;
      color: $base-black;

      &:hover {
        text-decoration: none;
      }
    }
  }
}
