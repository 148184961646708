@use "../../converSight/variables" as *;

.agent-container {
  display: flex;

  // This is desk-top style only.
  .callout-container {
    margin-left: -64px;
    filter: drop-shadow($shadow-medium);
    padding: 16px;
    width: 204px;
    height: fit-content;
    max-width: 204px;
    border-radius: $radius-small;
    background: $white;
    align-self: center;
    .speak-arrow {
      width: 17px;
      height: 19px;
      background: inherit;
      position: absolute;
      left: -6px;
      top: 36%;
      transform: rotate(49deg);
    }
  }

  // This is tablet/mobile style only.
  @include devices(medium) {
    .callout-container {
      display: none;
    }
  }
}
