@use "../../converSight/variables" as *;

.conversight.input-container {
  height: 38px;
  display: flex;
  align-items: center;
  img,
  svg {
    position: absolute;
    width: 28px;
    margin-left: 8px;
    color: $icon-color;

    &:has(+ input:disabled) {
      filter: grayscale(100%);
    }
  }
  input {
    width: 100%;
    height: 100%;
    padding: $small $medium !important;
    border: 1px solid $border;
    border-radius: $radius-medium;
    color: $base-black;

    &.invalid {
      border: 1px solid $error;
    }
    &.valid {
      border: 1px solid $success;
    }

    &.has-icon {
      padding-left: $large !important;
    }
    &:focus {
      outline: none;
      border: 1px solid $active-element;
    }
    &:disabled {
      background-color: $navigation-disabled;
      border-color: $navigation-disabled;
      color: $true-black;
      cursor: not-allowed;
    }
  }
}
