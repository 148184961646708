@use "../../converSight/mixins" as *;
.conversight.text-truncate {
  display: grid;
  max-width: 100%;
  & > * {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.conversight.text-fixed-width {
  font-variant-numeric: tabular-nums;
}

@include colors();
