@use "variables" as *;

$gaps: (
  "none": 0,
  "xsmall": $xsmall,
  "small": $small,
  "small-medium": $small-medium,
  "medium": $medium,
  "medium-large": $medium-large,
  "large": $large,
  "xlarge": $xlarge,
  "xxlarge": $xxlarge,
);

@mixin gaps() {
  @each $size, $gap in $gaps {
    &-gap-#{$size} {
      gap: $gap;
    }
  }
}

$grow: (
  "0": 0,
  "1": 1,
  "2": 2,
  "3": 3,
  "4": 4,
  "5": 5,
);

@mixin grow() {
  @each $size, $amount in $grow {
    &-grow-#{$size} {
      flex-grow: $amount;
    }
  }
}

$shrink: (
  "0": 0,
  "1": 1,
  "2": 2,
  "3": 3,
  "4": 4,
  "5": 5,
);

@mixin shrink() {
  @each $size, $amount in $shrink {
    &-shrink-#{$size} {
      flex-shrink: $amount;
    }
  }
}

$margins: (
  "none": 0,
  "auto": auto,
  "xsmall": $xsmall,
  "small": $small,
  "medium": $medium,
  "medium-large": $medium-large,
  "large": $large,
  "xlarge": $xlarge,
  "xxlarge": $xxlarge,
);

@mixin margins() {
  @each $size, $margin in $margins {
    &-margin-#{$size} {
      margin: $margin;
    }
  }
}

$padding: (
  "none": 0,
  "auto": auto,
  "xsmall": $xsmall,
  "small": $small,
  "medium": $medium,
  "medium-large": $medium-large,
  "large": $large,
  "xlarge": $xlarge,
  "xxlarge": $xxlarge,
);

@mixin padding() {
  @each $size, $pad in $padding {
    &-padding-#{$size} {
      padding: $pad;
    }
  }
}

$colors: (
  "base-blue": $base-blue,
  "navigation-active": $navigation-active,
  "navigation-inactive": $navigation-inactive,
  "base-black": $base-black,
  "white": $white,
  "dark-blue": $base-dark-blue,
  "dark-gray": $label-color,
  "true-black": $true-black,
  "icon-color": $icon-color,
  "error": $error,
  "divider": $divider,
);

@mixin colors() {
  @each $color, $value in $colors {
    .gc-color-#{$color} {
      color: $value;
    }
    .gc-bg-color-#{$color} {
      background-color: $value;
    }
  }
}

$radii: (
  "small": $radius-small,
  "medium": $radius-medium,
  "large": $radius-large,
  "x-large": $radius-x-large,
  "pill": $radius-pill,
  "fill": $radius-fill,
);

@mixin radii() {
  @each $radius, $value in $radii {
    .gc-radius-#{$radius} {
      border-radius: $value;
    }
  }
}

$textAlignments: (
  "left": left,
  "center": center,
  "right": right,
);

@mixin textAlignments() {
  @each $alignment, $value in $textAlignments {
    .gc-text-align-#{$alignment} {
      text-align: $value;
    }
  }
}
