.conversight.error-boundary-container {
  max-width: 600px;

  width: 100%;
  height: auto;
  background: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 20px;
  padding: 24px;
  text-align: center;
  box-shadow: 0px 2px 8px rgba(40, 41, 61, 0.04), 0px 16px 24px rgba(96, 97, 112, 0.16);

  img {
    width: 80px;
    height: 80px;
  }
  .error-body {
    display: flex;
    flex-direction: column;
    border: 2px dashed #0c020248;
    padding: 16px;
    margin-top: 16px;
    width: auto;

    code {
      color: lightgray;
    }
  }
  .footer {
    margin-top: 16px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    button {
      display: flex;
    }
  }
}
