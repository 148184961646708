@use "../../converSight/variables" as *;
@use "../../converSight/mixins" as *;

.conversight.list-group {
  display: flex;
  flex-direction: column;
  padding: 0px;

  &.max-height {
    max-height: 260px;
    overflow-y: auto;
  }

  &.use-shadow {
    & > :not(:last-child):not(:only-child) {
      border-bottom: 1px solid #eaeaea;
    }
  }
  @include padding();
}
