@use "../../converSight/variables" as *;

.conversight.new-version {
  // Static position in the lower right corner
  position: fixed;
  bottom: $medium;
  right: $medium;

  // Background color
  background-color: $white;

  // Border
  border-radius: $radius-medium;

  // Padding
  padding: $medium $large;

  // Width
  width: 400px;

  // Z index
  z-index: 1000;

  // On mobile devices, the banner should be centered horizontally
  // Use device mixin
  @include devices(mobile) {
    width: 100%;
  }
}
