@use "../../converSight/variables" as *;

div.conversight.modal-close-button {
  position: absolute;
  margin-top: $medium;
  margin-right: $medium;
  z-index: 2;
  right: 0;
  top: 0;
}
