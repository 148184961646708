@use "../../converSight/variables" as *;

div.conversight.conversight-progress-bar {
  background-color: #ecf1f8;
  border-radius: $radius-large $radius-large 0px 0px;
  height: 28px;
  width: 100%;

  .conversight-progress {
    background-color: $base-dark-blue;
    text-align: center;
    height: 100%;
    font-weight: 500;
    font-size: 14px;
    line-height: 28px;
    border-radius: $radius-large $radius-large $radius-large 0;
    color: #fff;

    &.last-step {
      border-radius: $radius-large $radius-large 0px 0px;
    }
  }
}
