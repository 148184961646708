@use "../../converSight/variables" as *;

.conversight.dropdown {
  position: relative;
  width: 100%;
  cursor: pointer;
  .flip-180 {
    transform: rotate(180deg);
  }
  .dropdown-content {
    margin-top: 4px;
    width: 100%;
    max-height: 70vh;
    overflow: auto;
    position: absolute;
    z-index: 2147483008;
    background-color: white;
    box-shadow: $shadow-medium;
    border-radius: $radius-medium;
  }
}
