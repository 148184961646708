.not-found {
  width: 100%;
  height: 100%;
}

.not-found .logo {
  position: relative;
  top: 64px;
  left: 64px;
  width: 189px;
  height: 58px;
}

.not-found .content {
  top: 30%;
  width: 100%;
  align-content: center;
  vertical-align: middle;
  position: absolute;
}

.not-found img {
  max-width: 743px;
  margin-right: 64px;
}
.not-found .text-container {
  padding: 24px;
  background: #ffffff;
  box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.04), 0px 2px 4px rgba(96, 97, 112, 0.16);
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  margin: 0px 110px;
  min-width: 375px;
}

.not-found .text-container button {
  padding: 10px 32px !important;
}

@media only screen and (max-width: 1024px) {
  .not-found .content {
    flex-direction: column;
    align-items: center;
  }
  .not-found img {
    margin-top: 24px;
  }
  .not-found .text-container {
    margin: 0px;
  }
}
