@use "../../converSight/variables" as *;

.conversight.selling-point-group {
  &-base-cyan {
    .conversight.selling-point svg {
      color: $base-cyan;
    }
  }
  &-base-dark-blue {
    .conversight.selling-point svg {
      color: $base-dark-blue;
    }
  }
}
