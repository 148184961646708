@use "../../converSight/variables" as *;
@use "../../converSight/mixins" as *;

@mixin header-styles() {
  margin-top: 0;
}

.action-area {
  display: flex;
  flex-direction: column;
  // padding: 24px;
  gap: $medium;

  @include devices(mobile) {
    justify-content: space-between;
  }

  &-min-width {
    min-width: 300px;
  }
  /* Border, Divider, Backgrounds/action br */

  &.bg-white {
    background: none;
  }
  &.bg-grey {
    background: $action;
  }
  border: 1px solid $action;

  border-radius: $radius-large;

  h1 {
    @include header-styles();
  }
  h2 {
    @include header-styles();
  }
  h3 {
    @include header-styles();
  }
  h4 {
    @include header-styles();
  }
  h5 {
    @include header-styles();
  }

  @include padding();
}
