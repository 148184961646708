@use "../../converSight/variables" as *;
// main search icon
$search-icon-size: 24px;
$search-icon-margin-left-right: 10px;

// cancel icon
$cancel-icon-size: 20px;

.conversight {
  .gc-search {
    display: flex;
    align-items: center;
    overflow: hidden;
    background-color: $white;
    border: 1px solid #e0e0e0;
    filter: drop-shadow($shadow-small);
    width: 48px;
    height: 48px;

    input {
      height: 50px;
      width: inherit;
      border: none;
      outline: none;
      &::placeholder {
        font-style: italic;
      }
    }

    & > * {
      background-color: $transparent-color;
    }
    &:not(.open) {
      &:hover {
        background-color: $standard-hover;
      }
    }
    .main-search-icon {
      color: $icon-color;
      margin-left: $search-icon-margin-left-right;
      margin-right: $search-icon-margin-left-right;
    }
    .cancel-icon {
      width: $cancel-icon-size;
      height: $cancel-icon-size;
      color: $light-black;
      margin-right: 15px;
    }
    &.rounded-border {
      border-radius: $radius-pill;
    }
    &.square {
      border-radius: $radius-small;
    }
    &.open {
      width: 100%;
      justify-content: center;
    }
  }
}
