@use "../../converSight/variables" as v;

.button-group {
  display: flex;
  flex-direction: row;
  padding: 0px;
  gap: 32px;
  flex: 1;

  &.justify-fill {
    &:not(.button-group-column) {
      align-items: center;
    }

    > div {
      flex: 1 1 0;
      flex-grow: inherit;
      justify-content: center;
    }

    @include v.devices(xsmall) {
      &:not(.button-group-column, .button-group-row) {
        flex-direction: column-reverse;
      }

      align-items: stretch;
      gap: 16px;
    }
  }

  &.justify-right {
    justify-content: right;
  }

  &.justify-between {
    justify-content: space-between;
  }

  &-default {
    flex-direction: row;
  }
  &-row {
    flex-direction: row;
  }
  &-row-reverse {
    flex-direction: row-reverse;
  }
  &-column-reverse {
    flex-direction: column-reverse;
  }

  &-column {
    flex-direction: column;
    align-items: stretch;
    gap: 16px;
  }

  @include v.devices(xsmall) {
    &:not(.button-group-column, .button-group-row) {
      flex-direction: column-reverse;
    }

    align-items: stretch;
    gap: 16px;
  }
}
