@use "../../converSight/variables" as *;
@use "../../converSight/mixins" as *;
div.conversight.frame {
  &.shadow-xs {
    box-shadow: $shadow-xs;
  }

  &.shadow-small {
    box-shadow: $shadow-small;
  }

  &.shadow-medium {
    box-shadow: $shadow-medium;
  }
}
@include radii();
