@use "../../converSight/variables" as *;

.conversight {
  .modal-dialog {
    &:not(.no-max-content-width) {
      max-width: fit-content;
    }

    margin: 0 auto;
  }

  &:not(.custom-spacing) {
    .modal-content {
      padding: $medium-large;

      /* HACKS. Fix this */
      .modal-title {
        position: relative;
        top: -$small;
      }
    }
  }

  &.x-small {
    @include devices(desktop) {
      .modal-content {
        width: 560px;
      }
    }

    @include devices(tablet) {
      .modal-content {
        width: fit-content;
      }
    }
  }

  &.small {
    @include devices(desktop) {
      .modal-content {
        width: 623px;
      }
    }

    @include devices(tablet) {
      .modal-content {
        width: fit-content;
      }
    }
  }

  &.medium {
    @include devices(xMobile) {
      .modal-content {
        width: fit-content;
      }
    }
  }

  &.large {
    // This is desk-top style only.
    @include devices(large) {
      .modal-content {
        width: 1000px;
        height: 600px;
      }
    }

    // This is mobile style only.
    @include devices(small) {
      .modal-content {
        padding: 16px;
      }
    }
  }

  &.x-large {
    // This is desk-top style only.
    @include devices(large) {
      .modal-content {
        height: 850px;
        width: 1000px;
      }
    }

    // This is mobile style only.
    @include devices(small) {
      .modal-content {
        padding: 16px;
      }
    }
  }
}
