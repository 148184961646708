@use "../../converSight/variables" as *;

.agent-container {
  &.show-name {
    flex-direction: column;
    align-items: center;
  }
  .agent-name {
    margin-top: 0.25rem;
  }
  .avatar {
    &.selected {
      box-shadow: 0 0 0 3px $base-blue;
    }
    &.xxx-small {
      width: 24px;
      height: 24px;
    }
    &.xx-small {
      width: 32px;
      height: 32px;
    }
    &.x-small {
      width: 56px;
      height: 56px;
    }
    &.small {
      width: 76px;
      height: 76px;
    }
    &.medium {
      width: 96px;
      height: 96px;
    }
    &.large {
      width: 240px;
      height: 240px;
    }
    &.x-large {
      width: 400px;
      height: 400px;
    }

    &.circle {
      border-radius: 100%;
      &.agent {
        &-1 {
          background: $hero-gray;
        }
        &-2 {
          background: $rosie-teal;
        }
        &-3 {
          background: $bella-gray;
        }
        &-4 {
          background: $alex-pink;
        }
        &-5 {
          background: $johny-gold;
        }
        &-6 {
          background: $graham-gray-blue;
        }
      }
    }
  }
}
