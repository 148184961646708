@use "../../converSight/variables" as *;

.conversight.pill {
  padding: 8px 16px 9px;
  border: 1px solid $border;
  border-radius: $radius-x-large;
  display: inline-flex;
  .content {
    display: flex;
    align-items: center;
  }
  &.active {
    background-color: $navigation-active;
    border-color: $base-blue;
    color: $base-blue;
  }
  &.disabled {
    color: $navigation-disabled;
    border: none;
  }
  &:hover {
    background-color: $standard-hover;
  }
}

div.conversight.caller-status-new {
  > div {
    background-color: #ffe6cf;
    min-width: 118px;
    padding: 4.2px 7.8px;
    border-radius: $radius-pill;
  }
  svg {
    width: 16px;
    height: 16px;
    color: $icon-color;
  }

  > div {
    width: 8rem;
  }

  &.mobile-card {
    > div {
      width: 5rem;
    }
  }
  span {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &.call-default {
    > div {
      background-color: #eaeaea;
    }
  }
  &.call-attention {
    > div {
      background-color: #ffe6cf;
    }
  }
  &.call-success {
    > div {
      background-color: #d5f6e4;
    }
  }
  &.call-failure {
    > div {
      background-color: #ffdcdc;
    }
  }
}
