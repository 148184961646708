@use "../../converSight/variables" as v;

.zapier-auth-modal {
  .agent-guide-container {
    padding: 2rem;
    justify-content: space-between;
    .agent-guide-meta {
      margin: unset !important;
    }
    .avatar {
      height: auto !important;
      &.x-large {
        width: 300px !important;
      }
    }
  }
}
