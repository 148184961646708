@use "../../converSight/variables" as *;

@mixin text-button() {
  padding: 6px 12px;
  line-height: 1.6;
}

@mixin loading() {
  &.loading {
    > div.button-content {
      visibility: hidden;
    }

    > div.spinner-border {
      position: absolute;
    }
  }
}
@mixin icon-button() {
  &-small {
    padding: 12px;
    width: 16px;
    height: 16px;
  }
  &-medium {
    padding: 16px;
    width: 24px;
    height: 24px;
  }
  &-large {
    padding: 24px;
    width: 48px;
    height: 48px;
  }

  &-circle {
    border-radius: $radius-fill;

    &-small {
      padding: 12px;
      width: 16px;
      height: 16px;
    }
    &-medium {
      padding: 16px;
      width: 24px;
      height: 24px;
      svg {
        font-size: 16px;
      }
    }
    &-large {
      padding: 16px;
      width: 32px;
      height: 32px;
      svg {
        font-size: 18px;
      }
    }
  }
}

@mixin button-interactions($color) {
  &:hover {
    background-color: $color;
    cursor: pointer;
  }
  &:disabled {
    background-color: $navigation-inactive;
    cursor: not-allowed;
  }

  @include loading();
  &-outlined {
    color: $color;
    background-color: transparent;
    border: 1px solid $color;
    &:disabled {
      color: $navigation-inactive;
      border-color: $navigation-inactive;
      background-color: transparent;
      cursor: not-allowed;
    }

    &:hover {
      background-color: $color;
      border-color: $color;
      color: $white;
    }
  }
}

button.conversight.conversight-button {
  position: relative;
  display: flex;
  width: 100%;
  align-items: center;
  border: none;
  border-radius: $radius-small;
  justify-content: center;

  > div.button-content {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
  }

  svg.MuiSvgIcon-root {
    display: flex;
    justify-content: center;
  }

  &.icon-left {
    svg.MuiSvgIcon-root {
      padding-right: 8px;
    }
  }

  &.icon-right {
    svg.MuiSvgIcon-root {
      padding-left: 8px;
    }
  }

  &.super-cta {
    background-color: $base-red;
    color: $white;
    @include text-button();
    @include button-interactions($super-cta-hover);

    &.icon {
      @include icon-button();
    }
  }

  &.agent-helper {
    background-color: $white;
    color: $base-dark-blue;
    @include text-button();
    @include button-interactions($white);

    &.icon {
      @include icon-button();
    }
  }

  &.primary {
    background-color: $base-blue;
    color: $white;
    @include text-button();
    @include button-interactions($primary-hover);

    &.icon {
      @include icon-button();
    }
  }

  &.secondary {
    background-color: $base-dark-blue;
    color: $white;
    @include text-button();
    @include button-interactions($secondary-hover);
    &-outlined {
      &:hover {
        background-color: $standard-hover;
        border-color: $base-dark-blue;
        color: $base-dark-blue;
      }
    }
    &.icon {
      @include icon-button();
    }
  }

  &.danger {
    background-color: $error;
    color: $white;
    @include text-button();
    @include button-interactions($super-cta-hover);
    &-outlined {
      &:hover {
        background-color: $standard-hover;
        border-color: $error;
        color: $error;
      }
    }
    &.icon {
      @include icon-button();
    }
  }
  &.tertiary-dark {
    background-color: transparent;
    color: $base-dark-blue;
    @include text-button();
    @include button-interactions($standard-hover);

    &.icon {
      @include icon-button();
    }
  }

  &.tertiary-light {
    @include text-button();
    background-color: transparent;
    color: $base-blue;
    @include loading();
    &:hover {
      background-color: $standard-hover;
      cursor: pointer;
    }
    &:disabled {
      background-color: transparent;
      cursor: not-allowed;
    }
    &-outlined {
      color: $base-blue;
      background-color: transparent;
      border: 1px solid $white;
    }

    &.icon {
      svg {
        color: $base-blue;
      }
      @include icon-button();
    }
  }

  &.white {
    @include text-button();
    background-color: transparent;
    color: $base-blue;

    &:hover {
      background-color: $standard-hover;
      cursor: pointer;
    }
    &:disabled {
      background-color: $navigation-inactive;
      cursor: not-allowed;
    }
    &-outlined {
      color: $base-blue;
      background-color: transparent;
      border: 1px solid $white;
    }

    &.icon {
      svg {
        color: $base-blue;
      }
      @include icon-button();
    }
  }
}
