@use "../../converSight/variables" as *;
@use "../../converSight/mixins" as *;

.list-item {
  &:not(.no-margin) {
    & > :last-child {
      margin-right: 10px;
      margin-left: 10px;
    }
    & > :not(:last-child) {
      margin-left: 10px;
    }
    & > :not(:first-child):not(:last-child) {
      flex-grow: 1;
    }
  }

  display: flex;
  align-items: center;

  .two-line,
  .one-line {
    max-width: 100%;
  }

  @include padding();

  &.enable-hover:hover {
    background-color: $standard-hover;
  }
}
